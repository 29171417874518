.my-{
  &aca-history {

    @include mq(sp, max) {
      // アイテム
      &_item {
        background-color: $white;
        margin-bottom: 15px;
  
        box-shadow: 0 0 4px rgba(27, 32, 33, 0.3);
        border-radius: 6px;
        overflow: hidden;
        padding: 20px;
  
        // 日付エリア
        &_date {
          display: flex;
          align-items: flex-end;

          margin-bottom: 20px;

          &_unit {
            flex-shrink: 0;

            margin-left: 5px;
            margin-right: 10px;

            font-size: 14px;
            font-weight: 600;

            &:last-child {
              margin-right: 0;
            }
          }

          .pf-select--002 {
            & + .pf-select--002 {
              margin-left: 10px;
            }
          }

          .pf-input--003 {
            .pf-input {
              &[type="number"] {
                width: 4em;
              }
            }
          }
        }

        // 会社名
        &_company {
          margin-bottom: 20px;
        }
      }

      // フォーム
      &_form {
        .my-aca-history_form_head {
          margin-bottom: 30px;

          .pf-txt {
            margin-bottom: 20px;
          }
        }

        .pf-txt {
          margin-bottom: 5px;
        }

        &_date {
          display: flex;
          align-items: flex-end;

          &_unit {
            flex-shrink: 0;

            margin-left: 5px;
            margin-right: 10px;

            font-size: 14px;
            font-weight: 600;

            &:last-child {
              margin-right: 0;
            }
          }

          .pf-select--002 {
            width: auto;
            & + .pf-select--002 {
              margin-left: 10px;
            }
          }

          .pf-input--003 {
            .pf-input {
              &[type="number"] {
                width: 4em;
              }
            }
          }
        }
      }
      
    }
    @include mq {
      // アイテム
      &_item {
        background-color: $white;
  
        box-shadow: 0 0 4px rgba(27, 32, 33, 0.3);
        border-radius: 6px;
        overflow: hidden;
        padding: 20px;

        &:not(:last-child) {
          margin-bottom: 15px;
        }
  
        // 日付エリア
        &_date {
          display: flex;
          align-items: flex-end;

          margin-bottom: 20px;

          &_unit {
            flex-shrink: 0;

            margin-left: 5px;
            margin-right: 10px;

            font-size: 12px;
            font-weight: 600;

            &:last-child {
              margin-right: 0;
            }
          }

          .pf-select--002 {
            & + .pf-select--002 {
              margin-left: 10px;
            }
          }

          .pf-input--003 {
            .pf-input {
              &[type="number"] {
                width: 4em;
              }
            }
          }
        }

        // 会社名
        &_company {
          margin-bottom: 20px;
        }
      }

      // フォーム
      &_form {
        .my-aca-history_form_head {
          margin-bottom: 30px;

          .pf-txt {
            margin-bottom: 20px;
          }
        }

        .pf-txt {
          margin-bottom: 5px;
        }

        &_date {
          display: flex;
          align-items: flex-end;

          &_unit {
            flex-shrink: 0;

            margin-left: 5px;
            margin-right: 10px;

            font-size: 12px;
            font-weight: 600;

            &:last-child {
              margin-right: 0;
            }
          }

          .pf-select--002 {
            width: auto;
            & + .pf-select--002 {
              margin-left: 10px;
            }
          }

          .pf-input--003 {
            .pf-input {
              &[type="number"] {
                width: 4em;
              }
            }
          }
        }
      }

      //ボタン　
      &-insert {
        max-width: 280px;
        margin: 0 auto;
      }

    }

  }
}
// SP077の求人カセットの余白調整で使用
.my- {
  &comp-008 {
    @include mq(sp, max) {
      margin-bottom: 60px;
      > .pf-ttl_wrap {
        margin-bottom: 20px;
      }
      > .pf-comp-009--contents {
        margin-bottom: 20px;
      }
      > .pf-card {
        margin-bottom: 20px;
      }
    }
    @include mq {
      margin-bottom: 60px;
      > .pf-ttl_wrap {
        margin-bottom: 20px;
      }
      > .pf-comp-009--contents {
        margin-bottom: 20px;
      }
      > .pf-card {
        margin-bottom: 20px;
      }
    }
    &_column {
      @include mq(sp, max) {
        margin-bottom: 10px;
        .pf-txt {
          margin-bottom: 10px;
        }
      }
      @include mq {
        padding-left: 16px;
        padding-right: 7px;
        margin-bottom: 10px;
        &--flex {
          display: flex;
          justify-content: space-between;
          align-items: baseline;
          &-right {
            justify-content: flex-end;
          }
        }
        &_ttl {
          flex-shrink: 0;
          flex-basis: 150px;
          max-width: 150px;
          margin-right: 10px;
        }
        &_body {
          flex-basis: calc(100% - 150px);
          max-width: calc(100% - 150px);
          .pf-txt--error {
            margin-top: 5px;
          }
        }
      }
    }
  }
}

.my- {
  &comp-005 {
    padding: 0 15px;
    background-color: $white;
    border-radius: 6px;
    box-shadow: 0 0 4px rgba(27, 32, 33, 0.3);
    .pf-list {
      &_item {
        display: block;
        border-top: none;
        border-bottom: 1px solid $grey-001;
        &:last-child {
          border-bottom: 0;
        }
        &_date {
          font-size: 12px;
          color: $grey-006;
          margin-bottom: 10px;
        }
        &_heading {
          font-size: 14px;
          margin-bottom: 10px;
        }
      }
    }
    .pf-link {
      font-size: 12px;
      &_wrap {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}

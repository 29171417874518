.my-{
  &offer-status{
    margin-bottom: 20px;
    @include mq{
      padding-bottom: 20px;
      border-bottom: 1px solid $grey-001;
    }
    &_inner{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
      background-color: #fff;
      color: $grey-003;
      font-weight: 600;
      font-size: 14px;
      @include mq{
        width: 325px;
        padding: 14px 20px;
        border-radius: 6px;
        background-color: $primary-light;
      }
    }
    &_em{
      color: $primary;
    }
  }
}

// 主に見出しで使用
.my- {
  &comp-006 {
    border-radius: 6px;
    background-color: #fff;
    padding: 20px 5px;
    margin-bottom: 20px;
    text-align: center;
    .pf-txt--003 {
      font-weight: 600;
    }
    @include mq {
      background-color: $grey-005;
      padding: 30px 5px;
      margin-bottom: 30px;
    }
  }
}

// SP034のモーダルで使用
.my- {
  &comp-002 {
    .pf-modal_text {
      font-size: 14px;
      text-align: center;
      margin-bottom: 20px;
      @include mq {
        font-size: 16px;
      }
    }
  }
}

.my- {
    &resume {
        &_wrap {
            > div {
                display: none;
                margin: 0px auto;
            }
        }
        &_put {
            >div {
              position: relative;
              width: 800px;
              margin: 0 auto;
            }
            .my-resume_put {
                position: absolute;
                display: flex;
                flex-direction: column;
                justify-content: center;
                font-family: 'Noto Sans JP',
                "Hiragino Sans",
                "Hiragino Kaku Gothic ProN",
                "Hiragino Kaku Gothic Pro",
                "ヒラギノ角ゴ ProN",
                "ヒラギノ角ゴ Pro",
                "ヒラギノ角ゴ Pro W3",
                "游ゴシック",
                "Yu Gothic",
                "游ゴシック体",
                "YuGothic",
                "ＭＳ Ｐゴシック",
                sans-serif;;
                font-weight: 400;

                /*日付*/
                &--001 {
                  height: 17px;
                  left: 237px;
                  top: 78px;
                  width: 300px;
                  font-size: 12px;
                  align-items: flex-end;
                }
                /*氏名*/
                &--002 {
                  height: 17px;
                  left: 153px;
                  top: 114px;
                  width: 376px;
                  font-size: 12px;
                }
                &--003 {
                  height: 46px;
                  left: 153px;
                  top: 157px;
                  width: 287px;
                  font-size: 32px;
                }
                /*生年月日*/
                &--004 {
                  height: 17px;
                  left: 153px;
                  top: 229px;
                  width: 374px;
                  font-size: 12px;
                  align-items: flex-end;
                }
                /*住所*/
                &--005 {
                  height: 28px;
                  left: 153px;
                  top: 254px;
                  width: 376px;
                  font-size: 10px;
                }
                &--006 {
                  height: 17px;
                  left: 168px;
                  top: 285px;
                  width: 150px;
                  font-size: 12px;
                }
                &--007 {
                  height: 33px;
                  left: 153px;
                  top: 303px;
                  width: 376px;
                  font-size: 12px;
                }
                &--008 {
                  height: 17px;
                  left: 587px;
                  top: 261px;
                  width: 130px;
                  font-size: 12px;
                }
                &--009 {
                  height: 29px;
                  left: 549px;
                  top: 300px;
                  width: 168px;
                  font-size: 10px;
                }
                /*連絡先*/
                &--010 {
                  height: 28px;
                  left: 153px;
                  top: 338px;
                  width: 376px;
                  font-size: 10px;
                }
                &--011 {
                  height: 17px;
                  left: 168px;
                  top: 368px;
                  width: 150px;
                  font-size: 12px;
                }
                &--012 {
                  height: 33px;
                  left: 153px;
                  top: 386px;
                  width: 376px;
                  font-size: 12px;
                }
                &--013 {
                  height: 17px;
                  left: 587px;
                  top: 344px;
                  width: 130px;
                  font-size: 12px;
                }
                &--014 {
                  height: 29px;
                  left: 549px;
                  top: 383px;
                  width: 168px;
                  font-size: 10px;
                }
                /*志望動機*/
                &--015 {
                  height: 212px;
                  left: 83px;
                  top: 589px;
                  width: 437px;
                  font-size: 14px;
                  justify-content: flex-start;
                }
                /*通勤時間*/
                &--016 {
                  height: 20px;
                  left: 541px;
                  top: 604px;
                  width: 176px;
                  font-size: 14px;
                  align-items: flex-end;
                  text-align: end;
                }
                /*扶養家族*/
                &--017 {
                  height: 20px;
                  left: 541px;
                  top: 685px;
                  width: 168px;
                  font-size: 14px;
                  align-items: flex-end;
                }
                /*希望欄*/
                &--018 {
                  height: 200px;
                  left: 83px;
                  top: 847px;
                  width: 634px;
                  font-size: 14px;
                  justify-content: flex-start;
                }
                /*学歴・職歴・資格*/
                &_wrap001 {
                  $height: 37px;
                  $left-top: 435px;
                  $right-top: 66px;
                  position: absolute;
                  width: 657px;
                  height: 38px;
                  left: 72px;
                  &>div {
                    &:nth-child(1) {
                      height: 20px;
                      left: 2px;
                      width: 63px;
                      font-size: 14px;
                      line-height: 17px;
                      top: 50%;
                      transform: translateY(-50%);
                      align-items: center;
                    }
                    &:nth-child(2) {
                      height: 20px;
                      left: 65px;
                      width: 44px;
                      font-size: 14px;
                      top: 50%;
                      transform: translateY(-50%);
                      align-items: center;
                    }
                    &:nth-child(3) {
                      height: 36px;
                      left: 120px;
                      width: 525px;
                      font-size: 14px;
                      top: 50%;
                      transform: translateY(-50%);
                    }
                  }
                  &--header {
                    &>div:nth-child(3) {
                      align-items: center;
                      font-weight: 500;
                    }
                  }
                  &--end {
                    &>div:nth-child(3) {
                      align-items: flex-end;
                      font-weight: 500;
                    }
                  }
                  /*一枚目*/
                  &--001 {
                    top: $left-top;
                  }
                  &--002 {
                    top: ($left-top+($height*1));
                  }
                  &--003 {
                    top:  ($left-top+($height*2));
                  }
                  &--004 {
                    top:  ($left-top+($height*3));
                  }
                  &--005 {
                    top:  ($left-top+($height*4));
                  }
                  &--006 {
                    top:  ($left-top+($height*5));
                  }
                  &--007 {
                    top:  ($left-top+($height*6));
                  }
                  &--008 {
                    top:  ($left-top+($height*7));
                  }
                  &--009 {
                    top:  ($left-top+($height*8));
                  }
                  &--010 {
                    top:  ($left-top+($height*9));
                  }
                  &--011 {
                    top:  ($left-top+($height*10));
                  }
                  &--012 {
                    top:  ($left-top+($height*11));
                  }
                  &--013 {
                    top:  ($left-top+($height*12));
                  }
                  &--014 {
                    top:  ($left-top+($height*13));
                  }
                  &--015 {
                    top:  ($left-top+($height*14));
                  }
                  &--016 {
                    top:  ($left-top+($height*15));
                  }
                  &--017 {
                    top:  ($left-top+($height*16));
                  }
                  /*二枚目*/
                  &--018 {
                    top:  ($right-top+($height*0));
                  }
                  &--019 {
                    top:  ($right-top+($height*1));
                  }
                  &--020 {
                    top:  ($right-top+($height*2));
                  }
                  &--021 {
                    top:  ($right-top+($height*3));
                  }
                  &--022 {
                    top:  ($right-top+($height*4));
                  }
                  &--023 {
                    top:  ($right-top+($height*5));
                  }
                  &--024 {
                    top:  ($right-top+($height*6));
                  }
                  &--025 {
                    top:  ($right-top+($height*7));
                  }
                  &--026 {
                    top:  ($right-top+($height*8));
                  }
                  &--027 {
                    top:  ($right-top+($height*9));
                  }
                  &--028 {
                    top:  ($right-top+($height*10));
                  }
                  &--029 {
                    top:  ($right-top+($height*11));
                  }
                  &--030 {
                    top:  ($right-top+($height*12));
                  }
                }

                /*チェック系*/
                &_check {
                  width: 24px;
                  height: 24px;
                  border: 1px solid $black;
                  border-radius: 50%;
                  /*一枚目*/
                  &--001 {
                    top: 170px;
                    left: 465px;
                  }
                  &--002 {
                    top: 170px;
                    left: 500px;
                  }
                  &--003 {
                    top: 763px;
                    left: 543px;
                  }
                  &--004 {
                    top: 763px;
                    left: 574px;
                  }
                  &--005 {
                    top: 763px;
                    left: 642px;
                  }
                  &--006 {
                    top: 763px;
                    left: 672px;
                  }
                }

                /*画像*/
                &_image {
                  &--001 {
                    width: 108px;
                    height: 148px;
                    left: 580px;
                    top: 80px;

                    background-color: $white;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center center;
                    margin: 0 auto;
                  }
                }
                
            }
        }
        &_tab {
            height: 40px;
            max-width: 372px;
            border: 1px solid $primary;
            border-radius: 20px;
            display: flex;
            margin: 0 auto;
            align-items: center;
            a {
                color: $primary;
                width: 100%;
                height: 30px;
                font-weight: 600;
                border-radius: 20px;
                margin: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                &:first-child {
                  margin-right: 0;
                }
                &:last-child {
                  margin-left: 0;
                }
                &.my-resume_tab--active {
                  color: $white;
                  background-color: $primary;
                }
                
                @include mq {
                  @include hover;
                }
            }
        }
    }
}
/**
 * Color System
 */
$white: #ffffff !default;
$black: #1b2021 !default;

$grey-001: #d8d8d8 !default;
$grey-002: #f0f0f0 !default;
$grey-003: #7e7e7e !default;
$grey-004: #5a5a5a !default;
$grey-005: #f7f7f7 !default;
$grey-006: #969696 !default;
$grey-007: #707070 !default;
$grey-008: #aaaaaa !default;
$grey-009: #bbbbbb !default;

$pink: #fef8f7 !default;

$primary: #238fe8 !default; /* プライマリーカラー */
$primary-light: #effbff !default; /* プライマリーカラー ライト */
$primary-clean: #91c7d9 !default; /* プライマリーカラー クリーン */

$secondary: #ef3e37 !default; /* セカンダリーカラー */

$accent: #ffb100 !default; /* アクセントーカラー */

$orange: #ff6b1c !default;

$yellow: #d9d22a !default;
$marker: #fffa7e !default;

$brown: #ad8257 !default;

$green: #00a88b !default;
$green-light: #d8f3e1 !default;

$colors: (
  white: $white,
  black: $black,
  grey-001: $grey-001,
  grey-002: $grey-002,
  grey-003: $grey-003,
  grey-004: $grey-004,
  grey-005: $grey-005,
  grey-006: $grey-006,
  grey-007: $grey-007,
  grey-008: $grey-008,
  pink: $pink,
  primary: $primary,
  primary-light: $primary-light,
  primary-clean: $primary-clean,
  secondary: $secondary,
  accent: $accent,
  orange: $orange,
  yellow: $yellow,
  marker: $marker,
  brown: $brown,
  green: $green,
  green-light: $green-light,
);

.my-{
  &exp-sentence {

    @include mq(sp, max) {
      &_head {
        margin-bottom: 30px;

        .pf-txt {
          margin-bottom: 20px;
        }
      }

      // リスト
      &_list {
        &_item {
          border-bottom: 1px solid $grey-001;

          padding: 15px;

          &:first-child {
            border-top: 1px solid $grey-001;
          }

          .pf-txt strong {
            display: block;
            margin-bottom: 5px;
          }
        }
      }
    }
    @include mq {
      &_head {
        margin-bottom: 30px;

        .pf-txt {
          margin-bottom: 20px;
        }
      }

      // リスト
      &_list {
        &_item {
          cursor: pointer;
          border-bottom: 1px solid $grey-001;

          padding: 15px;

          &:first-child {
            border-top: 1px solid $grey-001;
          }

          &:hover {
            background-color: $grey-002;
          }

          .pf-txt strong {
            display: block;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}
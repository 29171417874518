.my- {
  &sidemenu {
    @include mq(sp, max) {
      display: none;
    }
    @include mq {
      position: absolute;
      top: 67px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 90;

      max-width: 1012px;
      width: 100%;
      padding: 30px 16px 15px;

      pointer-events: none;
      
      &_inner {
        width: 27.551%;
        pointer-events: auto;
      }

      & ~ .pf-main {
        & > .pf-container > .pf-container_inner {
          padding-left: 32.214vw;
          @media only screen and (min-width: 1025px) {
            padding-left: 326px;
          }
        }
        & > .pf-container_wrap >.pf-container > .pf-container_inner {
          padding-left: 32.214vw;
          @media only screen and (min-width: 1025px) {
            padding-left: 326px;
          }
        }
      }
    }
    &.is-fixed {
      @include mq {
        position: fixed;
        top: auto;
        bottom: 0px;
      }
    }
  }
}

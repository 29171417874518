/**
 * arrow attachment
 *
 * 矢印アイコンを付与する
 * @param { left | top | right | bottom }  $direction 向き
 * @param { left | top | right | bottom }  $position  位置
 * @param { number }                       $height     大きさ
 * @param { number }                       $offset    オフセット
 * @param { color }                        $color     色
 */
@mixin arrow($direction: right, $position: right, $height: 0.784em, $offset: 0, $color: "white") {
  position: relative;

  &::after {
    content: '';
    background-repeat: no-repeat;
    background-size: contain;

    @if $color == "primary" {
      $color: '';
    } @else {
      $color: '--#{$color}';
    }

    @include mq(sp, max) {
      background-image: url("#{$branch}/common/images/ic-arrow-sp#{$color}.svg");
      width: #{$height * 0.581};
      height: #{$height};
    }
    @include mq {
      background-image: url("#{$branch}/common/images/ic-arrow-pc#{$color}.svg");
      width: #{$height * 0.650};
      height: #{$height};
    }

    position: absolute;

    $translate: translate(0);
    $rotate: rotate(0);

    @if $direction == 'left' {
      $rotate: rotate(180deg);
    } @else if $direction == 'top' {
      $rotate: rotate(-90deg);
    } @else if $direction == 'right' {
      $rotate: rotate(0);
    } @else if $direction == 'bottom' {
      $rotate: rotate(90deg);
    }

    @if $position == 'left' {
      top: 50%;
      left: $offset;

      $translate: translate(0, -50%);
    } @else if $position == 'top' {
      top: $offset;
      left: 50%;
      
      $translate: translate(-50%, 0);
    } @else if $position == 'right' {
      top: 50%;
      right: $offset;
      
      $translate: translate(0, -50%);
    } @else if $position == 'bottom' {
      bottom: $offset;
      left: 50%;
      
      $translate: translate(-50%, 0);
    }

    transform: #{$translate} #{$rotate};
  }
}

@mixin arrow-img($color) {
  @if $color == "primary" {
    $color: '';
  } @else {
    $color: '--#{$color}';
  }
  
  &::after {
    @include mq(sp, max) {
      background-image: url("#{$branch}/common/images/ic-arrow-sp#{$color}.svg");
    }
    @include mq {
      background-image: url("#{$branch}/common/images/ic-arrow-pc#{$color}.svg");
    }
  }
}
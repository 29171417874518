// SP077の求人カセットの余白調整で使用
.my- {
  &comp-007 {
    @include mq {
      .pf-img {
        width: 30%;
      }
      .pf-card_category {
        width: 51%;
      }
      .pf-card_desc {
        width: 64%;
      }
    }
  }
}

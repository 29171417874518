.my- {
  &comp-004 {
    padding: 20px;
    font-weight: 600;
    background-color: $primary-light;
    border-radius: 4px;
    max-width: 340px;
    width: 100%;
    display: flex;
    @include mq(sp, max) {
      margin: 0 auto;
    }
    @include mq {
      padding: 26px 20px;
    }
    &_ttl {
      flex-grow: 1;
    }
    &_count {
      flex-shrink: 0;
      margin-left: 10px;
    }
  }
}

.pf- {
  &resumearea {
    border-radius: 6px;
    padding: 30px 20px;
    @include mq {
      padding: 30px 40px;
      border: solid 1px $grey-001;
      box-shadow: 0 0 4px rgba(27, 32, 33, 0.3);
    }
    &_header {
      padding-bottom: 30px;
      border-bottom: 1px solid $primary;
      text-align: center;
      font-weight: bold;
      display: flex;
      justify-content: center;
      &::before {
        content: '';
        display: inline-block;
        width: 60px;
        height: 72px;
        background-image: url("#{$branch}/common/images/img-worker02.png");
        background-repeat: no-repeat;
        background-size: contain;
        vertical-align: middle;
      }
      &::after {
        content: '';
        display: inline-block;
        width: 60px;
        height: 72px;
        background-image: url("#{$branch}/common/images/img-worker.png");
        background-size: contain;
        background-repeat: no-repeat;
        vertical-align: middle;
      }
      &_ttl_wrap {
        margin: 0 10px;
        @include mq {
          padding: 10px 0;
        }
      }
      &_ttl {
        color: $primary;
        font-size: 26px;
        @include mq {
          font-size: 20px;
        }
        span {
          display: inline-block;
        }
      }
      &_subTtl {
        color: #000;
        display: block;
        font-size: 20px;
        @include mq {
          font-size: 14px;
        }
      }
    }
    &_items {
      margin-bottom: 20px;
      @include mq {
        margin-bottom: 0px;
      }
    }
    &_item {
      width: 100%;
      flex-grow: 1;

      display: flex;
      align-items: center;
      padding: 30px 0;
      border-bottom: 1px solid $primary;
      @include mq {
        &:last-child{
          border-bottom: none;
          padding-bottom: 0;
        }
      }
      &:last-child {
        border-right: 0;
        padding-right: 0;
      }
      &:first-child {
        padding-left: 0;
      }
      &--reverse {
        flex-direction: row-reverse;
        .pf-reason_item_img {
          text-align: right;
        }
      }
      &_img {
        width: 30.058%;
        img {
          width: auto;
        }
      }
      &_body {
        width: 100%;
        
        flex-grow: 1;
        padding-left: 10px;
        >* {
          margin-bottom: 20px;
          @include mq {
            text-align: center;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
        .pf-resumearea_item_ttl {
          margin-bottom: 10px;
          @include mq {
            margin-bottom: 0px;
          }
        }
        .pf-txt {
          .pf-link {
            text-decoration: underline;
          }
        }
      }
      &_ttl {
        font-size: 14px;
        font-weight: 600;
        span {
          display: inline-block;
        }
        &--medium {
          font-size: 16px;
        }
        &--strong {
          font-size: 26px;
          color: $primary;
        }
      }
      &_offer {
        display: flex;
        justify-content: space-between;
        padding: 15px;
        max-width: 375px;
        border-radius: 4px;
        margin: 0 auto 20px;
        background-color: $white;
        align-items: center;
        @include mq {
          background-color: $primary-light;
        }
        &_txt {
          font-size: 14px;
          color: $grey-003;
          font-weight: 600;
          span {
            color: $primary;
          }
        }
      }
      &_txt {
        font-size: 12px;
        @include mq {
          font-size: 14px;
        }
      }
    }
    &_footer {
      text-align: center;
      &_txt {
        font-size: 12px;
        font-weight: bold;
      }
      &_button {
        margin: 10px;
        .pf-button--001 {
          max-width: 275px;
          width: 100%;
          margin: 0 auto;
        }
      }
    }
    &_wrap {
      background-color: $primary-light;
      
      @include mq {
        background-color: transparent;
      }
    }
  }
}

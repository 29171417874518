// パンくずリストの余白で使用
.my- {
  &comp-001 {
    .pf-breadcrumb {
      margin-top: 15px;
      margin-bottom: 0px;
    }
    &--type002 {  
      &_sidemenu {
        @include mq(sp, max) {
          
        }
        @include mq {
          top: 100px;
        }
      }
    
      // パンくず
      &_breadcrumb {
        @include mq(sp, max) {
          
        }
        @include mq {
          .pf-container_inner {
            padding-left: 16px !important;
          }
        }
      }
    }
  }
}
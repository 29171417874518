// 主に見出しで使用
.my- {
  &comp-003 {
    padding-top: 30px;
    padding-bottom: 30px;
    .pf-ttl_wrap {
      margin-bottom: 20px;
    }
  }
}

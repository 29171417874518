.my-{
  &bargraph {
    @include mq(sp, max) {
      font-size: 14px;

      position: relative;
      padding: 0 2.5em;

      .my-bargraph_inner {
        position: relative;
        padding-top: 34px;
      }

      &_bar {
        width: 100%;
        height: 10px;
  
        background-color: $grey-001;
        border-radius: 5px;
        overflow: hidden;
  
        position: relative;
  
        &_progressbar {
          display: inline-block;
          width: 0%;
          height: 100%;
    
          background-color: $primary;

          position: absolute;
          top: 0;
          left: 0;

          // transition: width .3s;
        }
      }

      &_text {
        position: absolute;
        top: 0;
        left: 0%;
        transform: translateX(-50%);

        display: inline-block;

        color: $white;
        font-weight: 600;
        text-align: center;
        line-height: 1;

        background-color: $primary;
        border-radius: 4px;
        padding: 5px 11px;
        min-width: 5em;

        // transition: left .3s;

        &::after {
          content: '';
          position: absolute;
          left: 50%;
          bottom: 1px;
          transform: translate(-50%, 100%);

          width: 0;
          height: 0;
          border-top: solid 6px $primary;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
        }
      }
    }
    @include mq {
      font-size: 16px;

      position: relative;
      padding: 0 2.5em;

      .my-bargraph_inner {
        position: relative;
        padding-top: 37px;
      }

      &_bar {
        width: 100%;
        height: 10px;
  
        background-color: $grey-001;
        border-radius: 5px;
        overflow: hidden;
  
        position: relative;
  
        &_progressbar {
          display: inline-block;
          width: 0%;
          height: 100%;
    
          background-color: $primary;

          position: absolute;
          top: 0;
          left: 0;

          transition: width .3s;
        }
      }

      &_text {
        position: absolute;
        top: 0;
        left: 0%;
        transform: translateX(-50%);

        display: inline-block;

        color: $white;
        font-weight: 600;
        text-align: center;
        line-height: 1;
        min-width: 5em;

        background-color: $primary;
        border-radius: 4px;
        padding: 5px 11px;
        min-width: 5em;

        transition: left .3s;

        &::after {
          content: '';
          position: absolute;
          left: 50%;
          bottom: 1px;
          transform: translate(-50%, 100%);

          width: 0;
          height: 0;
          border-top: solid 6px $primary;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
        }
      }
    }
  }
}
.my-{
  &resumestep {
    display: flex;
    list-style-type: none;
    padding: 20px 15px 13px;
    margin: 0 auto 15px;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      height: 6px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 5px;
      background: $grey-001;
    }
      
    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background: $primary;
    }

    li {
      display: block;
      font-size: 12px;
      min-width: 74px;
      text-align: center;
      color: $primary;
      font-weight: 600;
      position: relative;
      a {
        color: $primary;
      }
      
      @include mq {
        min-width: 108px;

        @include hover;
      }
      &::before {
        z-index: 2;
        position: absolute;
        top: 13px;
        right: 25%;
        transform: translate(-50%, -50%);
        content: '';
        width: 50%;
        height: 1px;
        background-color: $primary;
      }
      &::after {
        z-index: 1;
        position: absolute;
        top: 13px;
        left: 75%;
        transform: translate(-50%, -50%);
        content: '';
        width: 50%;
        height: 1px;
        background-color: $primary;
      }
      
      &:last-child {
        &::after {
          content: none;
        }
      }
      
      &:first-child {
        &::before {
          content: none;
        }
      }

      span {
        position: relative;
        z-index: 3;
        width: 24px;
        height: 24px;
        padding-top: 2px;
        padding-left: 0px;
        border-radius: 50%;
        margin: 0 auto 3px;
        font-weight: 600;
        font-size: 14px;
        display: block;
  
        
        background-color: $white;
        color: $primary;
        border: 1px solid $primary;
        @include mq {
          width: 30px;
          height: 30px;
          padding-left: 1px;
          padding-top: 6px;
        }
      }
      
      
      &.my-resumestep-inactive {
        a {
          color: $grey-003;
        }
        span {
          background-color: $grey-003;
          color: $white;
          border: none;
        }
      }

      &.my-resumestep-active {
        color: $primary;
        span {
          background-color: $primary;
          color: white;
          border: none;
        }
      }

    }


  }

  &resumeinput {
    &_bottom {
      margin-top: 40px;
      >* {
        margin-bottom: 40px;
        &:first-child {
          margin-bottom: 35px;
        }
      }
    }
  }

  &completeness_header {
    color: $primary;
    font-size: 16px;
    text-align: center;
    margin-bottom: 10px;
    font-weight: 600;
    @include mq {
      font-size: 18px;
      margin-bottom: 15px;
    }

  } 

  .my-aca-history-insert,
  .my-license-list-insert,
  .my-work-history-insert {
    @include mq {
      max-width: 210px;
    }

  }
}
.my-{
  &sidemenu {
    @include mq {
      &_nav {
        $NAV: &;

        background-color: $white;
        border-radius: 6px;
        overflow: hidden;

        box-shadow: 0  0 6px rgba($black, 0.3);

        &_ttl {
          color: $white;
          font-size: 18px;
          font-weight: 600;
          padding: 1em;
          text-align: center;

          background-color: $primary;
        }


        &_item {
          border-bottom: 1px solid $grey-001;

          &:last-child {
            border-bottom: 0;
          }

          &_link {
            display: inline-block;
            width: 100%;
            padding: 11.5px;

            color: $primary;
            font-size: 14px;
            font-weight: bold;
            @include arrow(right, right, 0.971em, 15px, "primary-001");

            // アクティブ
            &.is-active {
              background-color: $primary;
              color: $white;
              pointer-events: none;
              @include arrow(right, right, 0.871em, 15px, "white");
            }

            &--has-child{
              &:after{
                display: none;
              }
            }
          }
        }
        &_menu-child{
          border-top: 1px solid $grey-001;
          padding: 10px 0 10px 30px;
          &_item{
            &+.my-sidemenu_nav_menu-child_item{
              margin-top: 5px;
            }
          }
          &_link{
            display: inline-block;
            padding-right: 30px;
            width: 100%;
            color: $grey-003;
            font-size: 14px;
            @include arrow(right, right, 0.871em, 15px, "grey-003");
            &.is-active{
              color: $primary;
              @include arrow(right, right, 0.971em, 15px, "primary-001");
            }
          }
        }

        &_bnr {
          padding: 11.5px;

          &_img {
            width: 100%;
          }
        }
      }
      &--resume {
        top: 100px;
      }
    }
  }
  
  // サポートサービス
  &spservice {
    @include mq(sp, max) {
      position: relative;
      &::after {
        content: "";
        background-image: url("#{$branch}/common/images/support-service.png");
        background-repeat: no-repeat;
        background-size: contain;

        position: absolute;
        top: 40px;
        right: 0;
        width: 84px;
        height: 100px;
      }

      .pf-txt {
        padding-right: 94px;
        margin-bottom: 30px;
      }
    }
    @include mq {
      position: relative;

      &::after {
        content: "";
        background-image: url("#{$branch}/common/images/support-service.png");
        background-repeat: no-repeat;
        background-size: contain;

        position: absolute;
        bottom: 0;
        right: 0;
        width: 100px;
        height: 120px;
      }

      .pf-txt {
        padding-right: 120px;
        margin-bottom: 20px;
      }
    }
    &--sidemenu {
      @include mq {
        border: 1px solid $primary;
        border-radius: 6px;
        padding: 10px;
        &::before {
          display: block;
          margin: 0 auto;
          content: "";
          background-image: url("#{$branch}/common/images/support-service.png");
          background-repeat: no-repeat;
          background-size: contain;
          width: 100px;
          height: 120px;
        }
        &::after {
          display: none;
        }
      }
      .pf-ttl {
        margin-top: 10px;
        text-align: center;
        color: $primary;
      }
    }
  }
}




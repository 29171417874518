/**
 * media queries
 *
 * ブレイクポイントに応じたメディアクエリを出力する
 * @param {string}  $breakpoint  ブレイクポイントの指定
 * @param {string}  $rule        minまたはmax、メディアクエリの条件として出力
 * @param {boolean} $addition min-width指定の場合にtrueなら、値を+1する
 * @include mq => @media screen and (#min-width: 769px)
 * @include mq(sp max true) => @media screen and (#max-width: 768px)
 */
@mixin mq($breakpoint: sp, $rule: min, $addition: true) {
  $breakpoint: map_get($breakpoints, $breakpoint);
  @if $rule == min and $addition {
    $breakpoint: $breakpoint + 1;
  }
  @media screen and (#{$rule}-width: $breakpoint) {
    @content;
  }
}

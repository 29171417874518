@for $count from 1 to 10 {
  [data-wordcount="#{$count}"] {
    max-height: calc(1.5em * #{$count});
    overflow: hidden;
    position: relative;
    text-align: justify;
    text-overflow: ellipsis;
    margin: 0;
    @supports (-webkit-line-clamp: 3) {
      -webkit-line-clamp: #{$count};
      -webkit-box-orient: vertical;
      display: -webkit-box;
    }
  }
  [data-wordcount-sp="#{$count}"] {
    @include mq(sp, max) {
      max-height: calc(1.5em * #{$count});
      overflow: hidden;
      position: relative;
      text-align: justify;
      text-overflow: ellipsis;
      margin: 0;
      @supports (-webkit-line-clamp: 3) {
        -webkit-line-clamp: #{$count};
        -webkit-box-orient: vertical;
        display: -webkit-box;
      }
    }
  }
  [data-wordcount-pc="#{$count}"] {
    @include mq {
      max-height: calc(1.5em * #{$count});
      overflow: hidden;
      position: relative;
      text-align: justify;
      text-overflow: ellipsis;
      margin: 0;
      @supports (-webkit-line-clamp: 3) {
        -webkit-line-clamp: #{$count};
        -webkit-box-orient: vertical;
        display: -webkit-box;
      }
    }
  }
}

.my- {
  &offersearch {
    @include mq(sp, max) {
      padding-top: 30px;
    }
    display: flex;
    width: 100%;
    justify-content: flex-end;
    &_wrap {
      margin-bottom: 15px;
    }
    &_select {
      background-color: #fff;
      font-size: 16px;
      cursor: pointer;
      padding: 10px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      appearance: none;
      border: none;
      box-shadow: 0 0 4px rgba($color: $black, $alpha: 0.3);
      border-radius: 6px;
      position: relative;
      color: $grey-003;

      @include mq {
        @include hover;
      }

      &_wrap {
        position: relative;
        flex-grow: 1;
        max-width: 240px;
        width: 100%;
        &::after {
          right: 14px;
          top: 10px;
          position: absolute;
          display: block;
          content: "";
          width: 10px;
          height: 10px;
          border: 1px solid;
          border-color: $grey-003 $grey-003 transparent transparent;
          transform: rotate(135deg);
        }
      }
      &::-ms-expand {
        display: none;
      }
    }
    &_button {
      flex-shrink: 0;
      width: 95px;
      margin-left: 20px;
      font-size: 16px;
    }
  }
}
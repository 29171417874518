.my- {
  &cv {
    &_area {
      width: 210mm;
      padding: 20mm 15mm;
      margin: 0px auto 0px;
      &_wrap {
        // overflow-x: auto;
        margin-top: 40px;
        margin-bottom: 40px;
        border: 1px solid $grey-001;
      }
    }

    &_sec {
      margin-top: 20px;
      margin-bottom: 20px;
      & +.my-cv_sec {
        margin-top: 40px;
      }
      &_inner {
        margin-bottom: 20px;
      }
    }

    &_ttl {
      padding-bottom: 10px;
      margin-bottom: 20px;
      font-size: 18px;
      font-weight: 600;
      border-bottom: 1px solid $grey-001;
    }

    &_txt {
      font-size: 14px;
      &_header {
        font-size: 14px;
        text-align: right;
      }
    }

    &_header {
      font-size: 22px;
      text-align: center;
      font-weight: 600;
    }

    &_table {
      width: 100%;
      margin-top: 20px;
      tr {
        border-top: 1px solid $grey-001;
        &:last-child {
          border-bottom: 1px solid $grey-001;
        }
      }
      th,td {
        padding: 20px;
      }
      th {
        text-align: left;
        width: 30%;
        background-color: $grey-005;
      }
      td {
        width: 70%;
      }
    }
  }
}